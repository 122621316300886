import {Box, Link} from "@mui/material";

const CONTRACT = process.env.REACT_APP_CONTRACT;
const REACT_APP_EXPLORER = process.env.REACT_APP_EXPLORER;
const REACT_APP_SCAN = process.env.REACT_APP_SCAN;

function Footer() {
    return (
        <>
            <Box sx={{textAlign: "center", mt: 4}}>
                © {new Date().getFullYear()}. All rights reserved.
            </Box>
            <Box sx={{alignContent: "center", alignItems: "center", mt: 1, display: "flex", gap: 1, justifyContent: "center"}}>
                <Link href={`${REACT_APP_SCAN}/${CONTRACT}`} target="_blank">Polygon Contract</Link>
                <Link href={`${REACT_APP_EXPLORER}/${CONTRACT}`} target="_blank">Opensea Collection</Link>
            </Box>
        </>
    );
}

export default Footer;
