import {createContext, useContext, useState} from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [customAuth, setCustomAuth] = useState({});

    const customLogin = (data) => {
        setCustomAuth((customAuth) => ({
            user: data.user, roles: data.roles,
        }));
    };

    const customLogout = () => {
        setCustomAuth((customAuth) => ({}));
    }

    const userRole = customAuth?.roles?.at(0)

    const values = {customAuth, customLogin, customLogout, userRole}

    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    )
}

const useCustomAuth = () => useContext(AuthContext);

export default useCustomAuth;