import Header from "./Header";
import {useDispatch, useSelector} from "react-redux";
import {selectCerts, setCerts} from "./app/reducers/certsSlice";
import {useMoralis, useMoralisWeb3Api} from "react-moralis";
import {useEffect} from "react";
import {Box} from "@mui/material";
import CertificateCard from "./certificate/CertificateCard";
import useMediaQuery from "@mui/material/useMediaQuery";

const CONTRACT = process.env.REACT_APP_CONTRACT;
const CHAIN = process.env.REACT_APP_CHAIN;

function ViewPublicCertificates() {
    const {isInitialized} = useMoralis();
    const matches = useMediaQuery('(max-width:767px)');
    const certificates = useSelector(selectCerts);
    const dispatch = useDispatch();
    const Web3Api = useMoralisWeb3Api();

    useEffect(() => {
        if(!certificates && isInitialized) getAllTokens()
    }, [certificates, isInitialized])

    const getAllTokens = async () => {
        const options = {
            address: CONTRACT,
            chain: CHAIN,
        };
        const NFTs = await Web3Api.token.getAllTokenIds(options);

        let tokens = NFTs?.result?.sort((a, b) => {return b.token_id - a.token_id});
        tokens = tokens.filter(t => {return (Number.parseInt(t.amount) > 0 && t.token_id !== '1')})
        dispatch(setCerts(tokens));
    }

    return (
        <>
            <Header />
            {certificates?.length > 0 ?
                <Box sx={{
                    display: 'grid',
                    ml: matches ? 2 : 0,
                    mr: matches ? 2 : 0,
                    gap: 3,
                    gridTemplateColumns: matches ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
                }} className="certificate-list">
                    {certificates?.map((certificate,i) => {
                        return (<CertificateCard key={i} certificate={certificate} />)
                    })}</Box>
                : <p style={{textAlign: "center"}}>Certificates are loading from the blockchain.</p>
            }
        </>
    );
}

export default ViewPublicCertificates;
