import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Link,
    Typography
} from "@mui/material";
import {IosShare, Login} from "@mui/icons-material";
import {useEffect, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CertificateDialog from "./CertificateDialog";
import {useMoralis} from "react-moralis";

function CertificateProp(props){
    return (
        <Typography gutterBottom variant="body" component="p" sx={{mb: 1.25, ...props.sx}}>
            {props?.children}
        </Typography>
    )
}

const REACT_APP_EXPLORER = process.env.REACT_APP_EXPLORER;
const REACT_APP_GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;

function CertificateCard(props) {
    const {certificate} = props
    const [publicData, setPublicData] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const matches = useMediaQuery('(max-width:414px)');
    const {Moralis} = useMoralis();

    useEffect(() => {
        if(certificate){
            const {metadata, token_address, token_id} = certificate;
            if(metadata){
                const {properties, image} = JSON.parse(metadata);
                const {client, doctor, clinics} = properties;
                const image_url = image.replace("ipfs://", `https://${REACT_APP_GATEWAY_URL}/ipfs/`);
                setPublicData({
                    doctor, clinics, image: image_url,
                    token: `${token_address}:${token_id}`,
                    link: `${REACT_APP_EXPLORER}/${token_address}/${token_id}`,
                    tokenId: token_id
                })
            }else{
                const query = new Moralis.Query('Certificate')
                query.equalTo('token', `${token_address}:${token_id}`)
                query.equalTo('isActive', true)
                query.first().then((cert) => {
                    const {publicData, image, image_url, objectId} = cert.attributes
                    const publicD = JSON.parse(publicData)
                    const doctor = `${publicD.institution.doctor.name} ${publicD.institution.doctor.surname}`
                    const clinics = `${publicD.institution.clinics.name}, ${publicD.institution.clinics.location}`
                    setPublicData({
                        doctor, clinics, image: image_url,
                        token: `${token_address}:${token_id}`,
                        link: `${REACT_APP_EXPLORER}/${token_address}/${token_id}`,
                        tokenId: token_id
                    })
                })
            }
        }
    }, [certificate])

    return (
        <>
            {publicData ? (
               <>
                   <Card variant="outlined">
                       <CardContent sx={{pb: 1}}>
                           <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", m: "1 0"}}>
                               <a href="https://magicneers.com/" target="_blank"><img src="/images/logo.svg" /></a>
                               <Typography gutterBottom variant="h5" component="div" sx={{lineHeight: 1, mt: "-2px"}}>
                                   #{parseInt(publicData?.tokenId)+1}
                               </Typography>
                           </Box>
                       </CardContent>
                       <CardMedia
                           image={publicData?.image}
                           component="img"
                           height="172"
                       />
                       <CardContent sx={{pb: 0}}>
                           <Typography gutterBottom variant="h6" component="p">
                               Magicneers Digital Certificate
                           </Typography>
                           <CertificateProp>
                               The certificate confirms the authenticity of Magicneers veneers.
                           </CertificateProp>
                           <CertificateProp>
                               All public data is stored <Link href={publicData?.link} target="_blank">on the blockchain</Link>.
                           </CertificateProp>
                           <CertificateProp>
                               <b>Dentist:</b> {publicData?.doctor}.
                           </CertificateProp>
                           <CertificateProp>
                               <b>Clinic:</b> {publicData?.clinics}.
                           </CertificateProp>
                       </CardContent>
                       <CardActions sx={{ padding: 2, flexDirection: !matches ? 'row' : 'column', gap: 1, pt: 1}}>
                           <Button variant="outlined" endIcon={<Login />} fullWidth={matches}
                                   href="/login" target="_blank">
                               Login to download 3D-scan
                           </Button>
                           <Button sx={{ml:"0!important"}} variant="contained" endIcon={<IosShare />} onClick={() => {setIsDialogOpen(true)}} fullWidth={matches}>
                               Share
                           </Button>
                       </CardActions>
                   </Card>
                   <CertificateDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} token={publicData?.token} />
               </>
            ) : (
                <Card variant="outlined">
                    <CardContent sx={{pb: 0}}>
                        Loading blockchain data...
                    </CardContent>
                </Card>
            )}
       </>
    );
}

export default CertificateCard;
