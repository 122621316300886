import {useMoralis} from "react-moralis";
import {Box, Card, CardActions, CardContent, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Login as LoginIcon} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {Link, useNavigate} from "react-router-dom";
import Moralis from "moralis-v1";
import useCustomAuth from "./AuthProvider";
import {Magic} from "magic-sdk";
import {ethers} from "ethers";

const MATIC_RPC = process.env.REACT_APP_MATIC_RPC;
const MATIC_ID = process.env.REACT_APP_MATIC_ID;
const MAGIC_KEY = process.env.REACT_APP_MAGIC_KEY;

const customNodeOptions = {
    rpcUrl: MATIC_RPC, // Polygon RPC URL
    chainId: Number.parseInt(MATIC_ID), // Polygon chain id
}

function Login() {
    const {isAuthenticated} = useMoralis();
    const [email, setEmail] = useState();
    const [isFormValid, setIsFormValid] = useState(false);
    const {customLogin} = useCustomAuth();
    const navigate = useNavigate();
    const [roles, setRole] = useState([])
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    const validateEmail = (email) => {
        // noinspection RegExpRedundantEscape
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    useEffect(() => {
        const isEmail = email && validateEmail(email);
        setIsFormValid(isEmail);
    }, [email]);

    useEffect(() => {
        if (isAuthenticated && roles.length === 0) setRoles()
    }, [isAuthenticated, roles])

    const setRoles = () => {
        Moralis.Cloud.run("promoteClient").then((roles) => {
            setRole(roles?.roles);
            customLogin(roles);
            navigate(roles?.roles.at(0) === 'guest' ? '/' : `/${roles?.roles.at(0)}`)
        });
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && isFormValid) {
            login();
        }
    }

    const login = async () => {
        try {
            setIsAuthenticating(true)
            let magic = new Magic(MAGIC_KEY, {
                network: customNodeOptions,
                locale: 'en'
            });
            if (magic?.user) {
                await magic?.user?.logout();
            }
            let ether = new ethers.providers.Web3Provider(magic.rpcProvider);
            await magic.auth.loginWithEmailOTP({
                email: email,
            });

            const magicUser = await magic?.user.getInfo()
            // console.log(magicUser)

            const ethAddress = magicUser?.publicAddress
            const { chainId } = await ether.getNetwork()

            const { message } = await Moralis.Cloud.run('requestMessage', {
                address: ethAddress,
                chain: chainId,
                network: 'evm',
            });

            const signer = ether.getSigner()
            const signature = await signer.signMessage(message);
            const authData = { id: ethAddress, signature, data: message };

            const user = await Moralis.User.logInWith('moralisEth', { authData });
            if (user) {
                if (user.getEmail() !== email) {
                    user.setEmail(email)
                    await user.save();
                }
                await setRoles();
                setIsAuthenticating(false);
            }

        } catch (err) {
            console.log(err)
        }

    }

    return (
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-start", height: "100vh"}}>
            <Box sx={{
                maxWidth: 388,
                margin: "0 auto",
                display: "flex",
                gap: 2,
                flexFlow: "column",
                alignItems: "center"
            }}>
                <Card variant="outlined">
                    <CardContent sx={{pb: 0}}>
                        <Typography gutterBottom variant="h5" component="div">
                            Login
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div">
                            Enter your email and one time code to sign in.
                        </Typography>
                        <Box sx={{mt: 2}}>
                            <TextField
                                fullWidth={true}
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                disabled={isAuthenticating}
                                onKeyDown={(e) => {
                                    return handleKeyDown(e)
                                }}
                            />
                        </Box>
                    </CardContent>
                    <CardActions sx={{padding: 2}}>
                        <LoadingButton
                            size="large"
                            disabled={!isFormValid && !isAuthenticated}
                            onClick={() => {
                                return login()
                            }}
                            endIcon={<LoginIcon/>}
                            loading={isAuthenticating}
                            loadingPosition="end"
                            variant="contained"
                            fullWidth={true}
                        >
                            Login
                        </LoadingButton>
                    </CardActions>
                </Card>
                <Box sx={{mt: 1, maxWidth: 128}}><Link to="/"><img src="/images/logo.svg" alt="logo"/></Link></Box>
            </Box>
        </Box>
    );
}

export default Login;
