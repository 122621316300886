import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: null,
    state: 'idle'
}

export const certsSlice = createSlice({
    name: 'certs',
    initialState,
    reducers: {
        setCerts: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const selectCerts = (state) => state.certs.value;

export const { setCerts } = certsSlice.actions;

export default certsSlice.reducer;
