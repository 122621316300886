import {Box, Button, IconButton, Typography} from "@mui/material";
import {Login, Logout, Warning} from "@mui/icons-material";
import {useMoralis} from "react-moralis";
import {Link, useNavigate} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useCustomAuth from "./auth/AuthProvider";

function Header() {
    const { logout, web3 } = useMoralis();
    const { customLogout, userRole } = useCustomAuth()
    const navigate = useNavigate();
    const matches767 = useMediaQuery('(max-width:767px)');
    const matches465 = useMediaQuery('(max-width:465px)');

    const logoutUser = async () => {
        customLogout();
        await logout();
    }

    return (
        <Box sx={{display: (matches465 && userRole === 'admin') ? "block" : "flex", alignItems: "center", justifyContent: "space-between", mb: 2, ml: 2, mr: 2}}>
            <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
                <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
                    <Link to={`/${userRole ? userRole : ''}`}><img src="/images/logo.svg" width={matches465 ? '150%' : 'auto'} alt="logo" /></Link>
                    <Typography gutterBottom variant="h6" component="div" sx={{mt: 0.375, display: matches767 ? 'none': 'block'}}>
                        Certificates
                    </Typography>
                </Box>
            </Box>
            <Box sx={{display: "flex", gap: 2, mt: (matches465 && userRole === 'admin') ? 2 : 0}}>
                {(userRole === 'admin') ? (
                    <>
                        <Button
                            size="medium"
                            variant="contained"
                            onClick={() => {navigate(`add`)}}
                            disabled={!web3}
                        >
                            {!web3 ? ("Authenticating...") : ("Create certificate")}
                        </Button>
                        {web3 && <IconButton variant="outlined" color="primary" aria-label="refresh" component="span" onClick={() => {navigate(`super`)}}>
                            <Warning />
                        </IconButton>}
                        <IconButton variant="outline" color="primary" aria-label="refresh" component="span" onClick={() => {return logoutUser()}}>
                            <Logout />
                        </IconButton>
                    </>
                ) : userRole ? (
                    <IconButton variant="outline" color="primary" aria-label="refresh" component="span" onClick={() => {return logoutUser()}}>
                        <Logout />
                    </IconButton>
                ) : (
                    <Link to={`/login`}><IconButton variant="outline" color="primary" aria-label="refresh" component="span">
                        <Login />
                    </IconButton></Link>
                )}
            </Box>
        </Box>
    );
}

export default Header;
