import {Box} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import Login from "./auth/Login";
import ViewCertificate from "./ViewCertificate";
import RequireAuth from "./auth/RequireAuth";
import {lazy} from "react";
import ViewPublicCertificates from "./ViewPublicCertificates";
import Footer from "./Footer";
const Admin = lazy(() => import("./admin/Admin"));
const Client = lazy(() => import("./client/Client"));
const About = lazy(() => import("./About"));
const NotFound = lazy(() => import("./NotFound"));

function Main() {
    return (
          <Box sx={{maxWidth: 783, ml: "auto", mr: "auto", mt: 6, mb: 6}}>
              <Box sx={{mt: 3}}>
                  <Routes>
                      <Route path="/">
                          {/*<Route element={<RequireAuth allowedRoles={['admin', 'client']} />}>*/}
                          {/*    <Route path="/" element={<Navigate to={customAuth?.roles?.at(0)} replace />} />*/}
                          {/*</Route>*/}
                          <Route path="/" element={<ViewPublicCertificates/>} />

                          <Route path="login" element={<Login />} />
                          <Route path="view/:tokenId" element={<ViewCertificate />} />

                          <Route element={<RequireAuth allowedRoles={['admin']} />}>
                              <Route path="admin/*" element={<Admin/>} />
                          </Route>
                          <Route element={<RequireAuth allowedRoles={['client']} />}>
                              <Route path="client/*" element={<Client/>} />
                          </Route>

                          <Route path="about" element={<About/>}/>
                          <Route path="404" element={<NotFound/>}/>
                          <Route path="*" element={<NotFound/>}/>
                      </Route>
                  </Routes>
              </Box>
              <Footer/>
          </Box>
    );
}

export default Main;
