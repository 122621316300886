import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl, IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from "@mui/material";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {ContentCopy} from "@mui/icons-material";
import {useEffect, useState} from "react";

function CertificateDialog(props){
    const {isDialogOpen, setIsDialogOpen, token} = props;
    const [currentDomain, setCurrentDomain] = useState();

    useEffect(() => {
        setCurrentDomain(`${window.location.protocol}//${window.location.host}/view/${token}`)
    }, [token])

    return (
        <Dialog open={isDialogOpen} onClose={() => {setIsDialogOpen(false)}}>
            <DialogContent sx={{maxWidth: 388}}>
                <Typography gutterBottom variant="h6" component="p">
                    Share
                </Typography>
                <DialogContentText>
                    Share the digital certificate of Magicneers veneers
                </DialogContentText>
                <FormControl sx={{ mt:3 }} fullWidth={true} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Link</InputLabel>
                    <OutlinedInput
                        // disabled={true}
                        contentEditable={false}
                        id="outlined-adornment"
                        type="text"
                        value={currentDomain}
                        endAdornment={
                            <CopyToClipboard text={currentDomain}>
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <ContentCopy />
                                    </IconButton>
                                </InputAdornment>
                            </CopyToClipboard>
                        }
                        label="Share"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ pr: 3, pl: 3, pt: 0, pb: 3}}>
                <Button
                    fullWidth={true}
                    href={currentDomain}
                    target="_blank"
                    variant="contained"
                    size="large"
                >Show certificate</Button>
            </DialogActions>
        </Dialog>
    )
}
export default CertificateDialog;