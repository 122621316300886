import Application from "./components/Application";
import { MoralisProvider } from "react-moralis";
import {createTheme, ThemeProvider} from "@mui/material";

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#0A6AA7',
        },
        secondary: {
            main: '#1EB7D9',
        },
    },
    typography: {
        h1: {
            fontFamily: '"Playfair Display", "Helvetica", "Arial", sans-serif',
            fontWeight: 800,
            lineHeight: 1.333,
        },
        h2: {
            fontFamily: '"Playfair Display", "Helvetica", "Arial", sans-serif',
            fontWeight: 800,
            lineHeight: 1.333,
        },
        h3: {
            fontFamily: '"Playfair Display", "Helvetica", "Arial", sans-serif',
            fontWeight: 800,
            lineHeight: 1.333,
        },
        h4: {
            fontFamily: '"Playfair Display", "Helvetica", "Arial", sans-serif',
            fontWeight: 800,
            lineHeight: 1.333,
        },
        h5: {
            fontFamily: '"Playfair Display", "Helvetica", "Arial", sans-serif',
            fontWeight: 800,
            lineHeight: 1.333,
        },
        h6: {
            fontFamily: '"Playfair Display", "Helvetica", "Arial", sans-serif',
            fontWeight: 800,
            lineHeight: 1.333
        },
        h7: {
            fontFamily: '"Playfair Display", "Helvetica", "Arial", sans-serif',
            fontWeight: 800,
            lineHeight: 1.333,
            marginTop: "1rem"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...({
                        textTransform: "none"
                    }),
                    ...(ownerState.variant === 'contained' && {
                        boxShadow: "none"
                    })
                })
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...({
                        padding: "6px"
                    })
                })
            }
        }
    }
});

function App() {
  return (
      <ThemeProvider theme={theme}>
          <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
            <Application/>
          </MoralisProvider>
      </ThemeProvider>
  );
}

export default App;
