import {Box, Card, CardContent} from "@mui/material";
import {useMoralis, useMoralisWeb3Api} from "react-moralis";
import {useParams} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useEffect, useState} from "react";
import CertificateCard from "./certificate/CertificateCard";

const CONTRACT = process.env.REACT_APP_CONTRACT;
const CHAIN = process.env.REACT_APP_CHAIN;

function ViewCertificate() {
    const { isInitialized } = useMoralis();
    const matches = useMediaQuery('(max-width:767px)');
    const {tokenId} = useParams();
    const [certificate, setCertificate] = useState();
    const Web3Api = useMoralisWeb3Api();
    const [error, setError] = useState();

    useEffect(() => {
       const fn = async () => {
           setError()
           getToken()
       }
       if(tokenId && isInitialized) fn()
    }, [tokenId, isInitialized])

    const getToken = async () => {
        const [address, token] = tokenId?.split(':')
        if(address && token && (address === String(CONTRACT).toLowerCase())){
            const options = {
                address: address,
                token_id: token,
                chain: CHAIN,
            };
            try{
                const cert = await Web3Api.token.getTokenIdMetadata(options);
                if(cert){
                    setCertificate(cert);
                }else{
                    setError(`The certificate doesn't exist`)
                }
            }catch (e) {
                setCertificate({
                    token_address: address,
                    token_id: token
                })
            }
        }else{
            setError(`The certificate doesn't exist`)
        }
    }

    return (
        <Box sx={{maxWidth: matches ? "100%" : "50%", ml: matches ? 2 : 'auto',
            mr: matches ? 2 : 'auto'}}>
            {error ? (
                <Card variant="outlined">
                    <CardContent>
                        {error}
                    </CardContent>
                </Card>
            ) : (
                <CertificateCard certificate={certificate} />
            )}
        </Box>
    );
}

export default ViewCertificate;
