import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Main from "./Main";
import {AuthProvider} from "./auth/AuthProvider";
import {useEffect, useState} from "react";
import MagicWeb3Connector from "./moralis/MagicWeb3Connector";
import {useMoralis} from "react-moralis";
import {Provider} from "react-redux";
import store from "./app/store";

const MAGIC_KEY = process.env.REACT_APP_MAGIC_KEY;

function Application() {
    const {Moralis, isWeb3Enabled, isAuthenticated} = useMoralis();
    const [enabled, setEnabled] = useState(false);

    const enableWeb3 = async () => {
        try{
            const user = Moralis.User.current();
            if(user && !isWeb3Enabled && !enabled){
                const providerOptions = {
                    email: user.get('email'),
                    provider: 'MagicLinkCustom',
                    connector: MagicWeb3Connector,
                    apiKey: MAGIC_KEY,
                    network: 'main',
                }
                Moralis.enableWeb3(providerOptions).then(res => {})
                setEnabled(true);
            }
        }catch(e){
            console.log(e)
        }
    }

    useEffect(() => {
        const fn = async () => {
            await enableWeb3();
        }
        if(isAuthenticated && !isWeb3Enabled) fn()

    }, [isAuthenticated, isWeb3Enabled])

    return (
        <Provider store={store}>
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route path="/*" element={<Main />}/>
                    </Routes>
                </AuthProvider>
            </Router>
        </Provider>
    );
}

export default Application;
