import { useLocation, Navigate, Outlet } from "react-router-dom";
import useCustomAuth from "./AuthProvider";

const RequireAuth = ({ allowedRoles }) => {
    const { customAuth } = useCustomAuth();
    const location = useLocation();

    return (
        customAuth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : customAuth?.user
            ? <Navigate to="/" state={{ from: location }} />
            : <Navigate to="/login" state={{ from: location }} />
    );
}

export default RequireAuth;